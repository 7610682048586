/* eslint-disable react/require-default-props */
/* eslint-disable dot-notation */
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { Group, MathUtils, Vector3 } from 'three';

type TumbleControlsProps = {
  view?: string;
  zoom: number;
};

export function TumbleControls({
  children,
  view = 'default',
  zoom,
}: PropsWithChildren<TumbleControlsProps>) {
  const { camera, gl } = useThree();
  const groupRef = useRef<Group>(null);

  useEffect(() => {
    const group = groupRef.current;

    if (!group) {
      return;
    }

    const onMouseUp = (mouseEvent: MouseEvent) => {
      group.userData['control'] = 'none';
    };

    const onMouseDown = (mouseEvent: MouseEvent) => {
      group.userData['control'] = mouseEvent.button === 2 ? 'pan' : 'rotate';
      group.userData['start'] = { x: mouseEvent.clientX, y: mouseEvent.clientY };
    };

    const onMouseMove = (mouseEvent: MouseEvent) => {
      if (group.userData && group.userData['control'] !== 'none') {
        const element = gl.domElement;
        const deltaX = mouseEvent.clientX - group.userData['start'].x;
        const deltaY = mouseEvent.clientY - group.userData['start'].y;

        if (group.userData['control'] === 'rotate') {
          const rotateAroundY = (2 * Math.PI * deltaX) / element.clientHeight;
          const rotateAroundX = (2 * Math.PI * deltaY) / element.clientHeight;

          const forward = new Vector3();
          camera.getWorldDirection(forward);

          const right = new Vector3();
          right.crossVectors(forward, camera.up);

          const offset = new Vector3().copy(group.position);
          group.position.set(0, 0, 0);

          group.rotateOnWorldAxis(right, rotateAroundX);
          group.rotateOnWorldAxis(camera.up, rotateAroundY);

          group.position.copy(offset);
        } else if (group.userData['control'] === 'pan') {
          // let targetDistance = camera.position.length();
          let targetDistance = -group.userData['zoom'];

          const fov = 50;
          targetDistance *= Math.tan(((fov / 2) * Math.PI) / 180.0);

          const moveX = (2 * deltaX * targetDistance) / element.clientHeight;
          const moveY = (-2 * deltaY * targetDistance) / element.clientHeight;

          group.position.x += moveX;
          group.position.y += moveY;
        }
      }

      group.userData['start'] = { x: mouseEvent.clientX, y: mouseEvent.clientY };
    };

    const onMouseWheel = (wheelEvent: WheelEvent) => {
      const forward = new Vector3();
      camera.getWorldDirection(forward);
      group.position.addScaledVector(forward, wheelEvent.deltaY * 0.5);
    };

    if (group && group.userData['control'] === undefined) {
      group.userData['control'] = 'none';
      group.userData['zoom'] = -zoom;
    }

    gl.domElement.addEventListener('mouseup', onMouseUp);
    gl.domElement.addEventListener('mousedown', onMouseDown);
    gl.domElement.addEventListener('mousemove', onMouseMove);
    gl.domElement.addEventListener('wheel', onMouseWheel);

    // eslint-disable-next-line consistent-return
    return () => {
      gl.domElement.removeEventListener('mouseup', onMouseUp);
      gl.domElement.removeEventListener('mousedown', onMouseDown);
      gl.domElement.removeEventListener('mousemove', onMouseMove);
      gl.domElement.removeEventListener('wheel', onMouseWheel);
    };
  }, [camera, gl, groupRef, zoom]);

  useEffect(() => {
    const group = groupRef.current;

    if (!group) {
      return;
    }

    // Reset zoom
    group.position.set(0, 0, group.userData['zoom']);

    // Set rotation
    switch (view) {
      case 'top':
        group.rotation.x = MathUtils.degToRad(-90);
        group.rotation.y = MathUtils.degToRad(180);
        group.rotation.z = 0;
        break;

      case 'bottom':
        group.rotation.x = MathUtils.degToRad(90);
        group.rotation.y = 0;
        group.rotation.z = 0;
        break;

      case 'front':
        group.rotation.x = 0;
        group.rotation.y = MathUtils.degToRad(180);
        group.rotation.z = 0;
        break;

      case 'back':
        group.rotation.x = 0;
        group.rotation.y = 0;
        group.rotation.z = 0;
        break;

      case 'left':
        group.rotation.x = 0;
        group.rotation.y = MathUtils.degToRad(-90);
        group.rotation.z = 0;
        break;

      case 'right':
        group.rotation.x = 0;
        group.rotation.y = MathUtils.degToRad(90);
        group.rotation.z = 0;
        break;

      default:
        group.rotation.x = MathUtils.degToRad(35);
        group.rotation.y = MathUtils.degToRad(-15);
        group.rotation.z = 0;
        break;
    }
  }, [camera, groupRef, view]);

  return <group ref={groupRef}>{children}</group>;
}
